import * as R from 'ramda'
import {
  VASItem,
  EquipmentVASItem,
  Price,
  PricingScope,
  GroupedVASEntity,
  NewDebugFields,
  ClientFrontlineConfig,
  OfferSelection,
  SapKey,
  EquipmentGroup,
  VasPricebook,
  GendocPriceGroupObject,
  Selections,
  FrontlineConfigJson,
  ElevatorSetupFeePaid,
  EscalatorSetupFeePaid,
  EquipmentSetupFeePaidBase,
  VasSetupfeeSavedData,
  VASSetUpFeeServiceDetails,
  ServiceLevelVASDiscountPrice,
  VasSetupfeePaidStatus,
  SelectedVasServices,
  EquipmentGroups,
  VasEquipmentInGroup,
  VasServiceGroup,
  VasSetupFeeZconInfo,
  VasSetupFeeSAPNode,
  ModuleName,
  VASItemPriceLabelKey
} from '../../types/types'
import { round, sumPrices } from '../prices'
import {
  DebugLine,
  EquipmentCategory,
  EquipmentProfile,
  CommonValueAddedServicesOptions,
  CommonElevatorEscalatorVASServicesOptions,
  EscalatorOnlyVASServiceOptions,
  ValueAddedServicesForElevatorOnlyOptions,
  EquipmentType
} from 'nemo-pricing'
import { GendocGroupPrefix } from '../pricing/price-breakdown-types'
import { GendocPriceGroups, CommonEquipmentVASGroups } from '../../backend/integration/gendoc/standard-types'
import { ensureTwoDecimals, IVasKey, ServiceKey, IVas } from '../../backend/integration/gendoc/common'
import { FrontlineConfig } from '../../client/js/state/frontline-config'
import { bundleDiscount, bundleDiscountedPrice } from '../pricing/bundle-discount-breakdown'
import * as i18nUtils from '../../client/js/nemo/i18n/utils'
import { PricingCategory } from '../../backend/db/tables'
import { GroupPriceBreakdown, GroupSectionPriceBreakdown } from '../pricing/price-breakdown-types'
import * as Gendoc from './../../backend/integration/gendoc/standard-types'
import { vasSetupFeeSapValueMapping } from '../../backend/integration/sap/sap-utils'
import { checkPriceHasVasDiscountDetails } from '../pricing/rebuild-equipment-groups'

let value_added_services: VASItem[] = []
let gsm_services: VASItem[] = []

// TODO: Remove as part of GSM Clean up
export const gsmVasOfferingKey: VASItemPriceLabelKey[] = ['kcsm_gsm_sales_split', 'kcsm_voice_link_inst']
export const gsmOfferingSapKey: SapKey[] = ['KCSM_GSM_SALES_SPLIT', 'KCSM_VOICE_LINK_INST']

export type VasSetupFeeEntity = EquipmentSetupFeePaidBase & ElevatorSetupFeePaid & EscalatorSetupFeePaid

export type VasOfferingConfig = Record<'escalator' | 'valueAddedServices', OfferSelection[]>
type GSMOffering = Record<'gsm', OfferSelection[]>

export const initializeVASConfig = (config: VasOfferingConfig) => {
  const vasOfferings = R.pipe(
    Object.values,
    (a: OfferSelection[][]) => R.uniqBy(x => x.key, R.flatten(a)),
    R.map((item: OfferSelection) => ({ key: item.key, ...item.vas_config_data } as VASItem))
  )(config)
  const vasGsmVasConfig = vasOfferings.concat(gsm_services)
  value_added_services = R.uniqWith((a, b) => a.key === b.key, vasGsmVasConfig)
}

export const initializeGSMConfig = (config: GSMOffering) => {
  gsm_services = R.pipe(
    Object.values,
    (a: OfferSelection[][]) => R.uniqBy(x => x.key, R.flatten(a)),
    R.map((item: OfferSelection) => ({ key: item.key, ...item.vas_config_data } as VASItem))
  )(config)
}

FrontlineConfig.subscribe((frontConfigResponse: FrontlineConfigJson) => {
  const {
    offerSelections: { group }
  } = frontConfigResponse
  const vasConfig: VasOfferingConfig = {
    valueAddedServices: group.valueAddedServices,
    escalator: group.escalator.filter(q => q.module === 'value_added_services')
  }

  const gsmOfferingConfig: GSMOffering = {
    gsm: group.gsm_campaign
  }
  initializeGSMConfig(gsmOfferingConfig)
  initializeVASConfig(vasConfig)
})

// Used by Offering, Pricing UI
export const getTranslationKey = (category: string | undefined): string => {
  const vas = value_added_services.find((v: VASItem) => v.category === category)
  return vas && vas.categoryTranslationKey ? vas.categoryTranslationKey : ''
}

const getVASTranslationKey = (key: SapKey) => i18nUtils.title(key)

// To get category in ScopelessModuleGroupOverview page
export const getCategoryFromKey = (
  key: string,
  category: ModuleName | undefined = undefined
): { category: string; icon: string; categoryTranslation: string } => {
  const filteredVas = value_added_services.find((v: VASItem) => v.key === key)
  // TODO: Remove as part of GSM Clean up
  if (category && category === 'gsm_campaign') {
    return {
      categoryTranslation: category,
      category,
      icon: ''
    }
  }
  return {
    categoryTranslation: !filteredVas || !filteredVas.categoryTranslationKey ? '' : filteredVas.categoryTranslationKey,
    category: !filteredVas || !filteredVas.category ? '' : filteredVas.category,
    icon: getIconName('category', '', filteredVas)
  }
}

export const getIconName = (key: keyof VASItem, filterValue: string = '', filteredVasItem?: VASItem): string => {
  const vas = filteredVasItem || value_added_services.find((v: VASItem) => v[key] === filterValue)
  return vas && vas.iconName ? vas.iconName : ''
}

const getSumOfPrices = (
  eqItems: EquipmentVASItem[],
  priceKey: 'priceYearly' | 'priceMonthly',
  isVasDiscount: boolean
) => {
  const calculatedPrice = eqItems.reduce((sum: number, eqItem: EquipmentVASItem) => sum + eqItem[priceKey], 0)
  return isVasDiscount && priceKey === 'priceMonthly' ? calculatedPrice : round(calculatedPrice, 2)
}

export function getGroup(key: SapKey) {
  const group = value_added_services.find(service => service.key === key)
  if (group) {
    return group.group as IVasKey
  }
  return undefined
}

export function getPricesByEquipmentCategory(
  prices: Price[],
  quickTenderScope: PricingScope | null,
  eqCategory: EquipmentCategory
) {
  const pricesByEquipmentCategory = prices.filter(price => price.priceDetails.profile.equipmentCategory === eqCategory)
  return getGroupedVasItemsForEquipmentGroup(pricesByEquipmentCategory, quickTenderScope, true)
}

export function getGroupedVasItemsForEquipmentGroup(
  prices: Price[],
  quickTenderScope: PricingScope | null,
  isVASServiceLevel: boolean = false,
  isServiceGrouped: boolean = false
): Array<GroupedVASEntity> {
  const vasGroupsPerEquipment: Record<string, EquipmentVASItem[]> = prices.reduce(
    (groups: Record<string, EquipmentVASItem[]>, p: Price) =>
      getVasEntitiesInEquipment(groups, p, quickTenderScope, isVASServiceLevel, isServiceGrouped),
    {}
  )
  const isVasDiscountEnabled = checkPriceHasVasDiscountDetails(prices)
  return Object.keys(vasGroupsPerEquipment).map((category: string) => ({
    name: category,
    translationKey: isVASServiceLevel ? getVASTranslationKey(category as SapKey) : getTranslationKey(category),
    iconName: getIconName('category', category),
    equipments: vasGroupsPerEquipment[category],
    vasServiceGroupEquipment: isServiceGrouped ? makeVASServiceGroup(vasGroupsPerEquipment[category]) : [],
    priceMonthly: getSumOfPrices(vasGroupsPerEquipment[category], 'priceMonthly', isVasDiscountEnabled),
    priceYearly: getSumOfPrices(vasGroupsPerEquipment[category], 'priceYearly', isVasDiscountEnabled)
  }))
}

const makeVASServiceGroup = (equipmentVasArray: EquipmentVASItem[]) => {
  const groupVasItem: EquipmentVASItem[][] = []
  R.uniq(R.pluck('sapKeys', equipmentVasArray)).forEach(sapKey => {
    groupVasItem.push(equipmentVasArray.filter(vasItem => vasItem.sapKeys === sapKey))
  }, equipmentVasArray)
  return groupVasItem
}
const isValidAnswer = (valuesVasKeyInProfile: string | string[]): boolean =>
  !['0', 'NA'].reduce(
    (isInvalid: boolean, invalidChoice: string) =>
      isInvalid ||
      (Array.isArray(valuesVasKeyInProfile)
        ? valuesVasKeyInProfile.length === 0 ||
          valuesVasKeyInProfile.join(',').toLowerCase().includes(invalidChoice.toLowerCase())
        : valuesVasKeyInProfile === invalidChoice),
    false
  )

export const getSelectedServices = (profile: EquipmentProfile) => {
  const profileKeys = Object.keys(profile)
  const profileValues = Object.values(profile)
  return value_added_services.filter(vasServices => {
    const indexVasKeyInProfile = profileKeys.indexOf(vasServices.key)
    const valuesVasKeyInProfile: string | Array<string> = profileValues[indexVasKeyInProfile]
    return indexVasKeyInProfile >= 0 && valuesVasKeyInProfile != null && isValidAnswer(valuesVasKeyInProfile)
  })
}

export function getValidVasItems(price: Price, scope: PricingScope | null) {
  const Prices = price.priceDetails.price as NewDebugFields
  const serviceInUse = Prices.hasOwnProperty('debugValueAddedServices')
    ? Prices.debugValueAddedServices.map((service: DebugLine) => service.serviceCode)
    : []
  const {
    priceDetails: { profile }
  } = price
  const services = serviceInUse.concat(
    isGsmSubServicesSelected(
      getSelectedServices(profile).map(item => item.key),
      scope
    )
  )
  return getSelectedServices(profile).filter(vasItem => R.uniq(services).includes(vasItem.key))
}

const getGSMServices = () => [
  'KCSM_GSM_UPG_PKGSALE',
  'KCSM_GSM_UPG_PKGSALE_1',
  'KCSM_GSM_UPG_PKGSALE_2',
  'KCSM_GSM_UPG_PKGSALE_3',
  'KCSM_GSM_UPG_PKGSALE_4',
  'KCSM_GSM_UPG_PKGSALE_5'
]

const isGsmSubServicesSelected = (services: string[], scope: PricingScope | null) => {
  if (scope !== 'gsm_campaign') {
    return services
  }
  const gsmMainUpgradePackage = 'KCSM_GSM_UPG_PKGSALE'
  const selectedgsmServices = R.intersection(services, getGSMServices())
  if (selectedgsmServices.length > 1 && selectedgsmServices.includes(gsmMainUpgradePackage)) {
    const gsmPackageIndex = selectedgsmServices.indexOf(gsmMainUpgradePackage)
    return R.remove(gsmPackageIndex, 1, selectedgsmServices)
  } else {
    return selectedgsmServices
  }
}

export const isGSMServices = (sapKey: SapKey, quickTenderScope: PricingScope | null) =>
  quickTenderScope === 'gsm_campaign' && getGSMServices().includes(sapKey)

export function getSavedVasSetupFeeKeys(setupFee: VasSetupfeeSavedData | undefined, category: EquipmentCategory) {
  if (setupFee && setupFee.serviceLevelVASSetupDiscount) {
    const equipmentEnabledVAS = setupFee!.serviceLevelVASSetupDiscount![category as EquipmentCategory]
    return equipmentEnabledVAS ? Object.keys(equipmentEnabledVAS) : []
  }
  return []
}

export const getDebugLabels = (vasItems: VASItem[], quickTenderScope: PricingScope | null): string[] =>
  vasItems.reduce((labels: Array<string>, vas) => {
    const quickTenderScopeItem = quickTenderScope ? vas[quickTenderScope] : null
    return labels.concat([...(quickTenderScopeItem || []), vas.debug_label])
  }, [])

export const getMonthlyPriceForVasItems = (
  items: VASItem[],
  price: Price,
  quickTenderScope: PricingScope | null
): number => {
  const debug_labels = getDebugLabels(items, quickTenderScope)
  const isVasDiscountEnabled = checkPriceHasVasDiscountDetails([price])

  return debug_labels.reduce(
    (sum: number, label: string) =>
      sum +
      (isVasDiscountEnabled
        ? checkPriceForVasDiscountFromDebugKey(price, label) || 0
        : getVasItemMonthlyPrice(price, label)),
    0
  )
}

export const vasDebugLabelForKey = (key: SapKey) =>
  value_added_services.find(vasItem => vasItem.key === key)!.debug_label

export const vasCategoryForKey = (key: SapKey) => value_added_services.find(vasItem => vasItem.key === key)!.category

function getVasEntitiesInEquipment(
  groups: Record<string, EquipmentVASItem[]>,
  price: Price,
  quickTenderScope: PricingScope | null,
  isVASServiceLevel: boolean,
  isServiceGrouped: boolean = false
): Record<string, EquipmentVASItem[]> {
  const vasItems = getValidVasItems(price, quickTenderScope)
  const includedVasEntitiesByGroups: Record<string, VASItem[]> = R.groupBy(R.prop('category'), vasItems)
  if (!isVASServiceLevel) {
    Object.keys(includedVasEntitiesByGroups).forEach(category => {
      const sapKeys = includedVasEntitiesByGroups[category].map(vas => vas.key)
      isServiceGrouped
        ? includedVasEntitiesByGroups[category].forEach((vas: VASItem) => {
            groups = getEquipmentVASItem(groups, price, quickTenderScope, [vas], category, vas.key)
          })
        : (groups = getEquipmentVASItem(
            groups,
            price,
            quickTenderScope,
            includedVasEntitiesByGroups[category],
            category,
            sapKeys
          ))
    })
  } else {
    vasItems.forEach((vas: VASItem) => {
      groups = getEquipmentVASItem(groups, price, quickTenderScope, [vas], vas.key, vas.key)
    })
  }
  return groups
}

function getEquipmentVASItem(
  groups: Record<string, EquipmentVASItem[]>,
  price: Price,
  quickTenderScope: PricingScope | null,
  vasItem: VASItem[],
  key: string,
  sapKeys: SapKey[] | SapKey
): Record<string, EquipmentVASItem[]> {
  const priceMonthly = getMonthlyPriceForVasItems(vasItem, price, quickTenderScope)
  if (groups[key] == null) {
    groups[key] = []
  }
  const paidServices = price.priceDetails.profile.vasSetupFeePaidServices
  const isVASSetupFeePaid = !Array.isArray(sapKeys)
    ? paidServices?.find(services => services.key === sapKeys)?.isSetupfeePaid
    : false
  const isVASSetupFeeCRMPaid = !Array.isArray(sapKeys)
    ? paidServices?.find(services => services.key === sapKeys)?.isSetupFeePaidInCRM
    : false
  const discountedPrice = price.bundlePrice ? price.bundlePrice.find(pd => pd.key === sapKeys)?.discountedPrice : 0
  groups[key].push({
    equipmentId: price.id,
    sapKeys,
    priceMonthly,
    priceYearly: priceMonthly * 12,
    groupId: price.groupId,
    vasServicesCRMSetupFeePaid: isVASSetupFeeCRMPaid,
    vasServicesWithSetupFeePaid: isVASSetupFeePaid,
    vasServicesWithDiscountedPrice: discountedPrice || 0
  })
  return groups
}

const getServices =
  (frontlineConfig: ClientFrontlineConfig) =>
  (quickTenderScope: PricingScope | null, equipmentCategory: EquipmentCategory): OfferSelection[] => {
    const {
      offerSelections: { group: selectionGroup }
    } = frontlineConfig
    switch (quickTenderScope) {
      case 'flexible':
        return selectionGroup[equipmentCategory] || []
      case 'konexion':
        return selectionGroup.additionalServices || []
      case 'kone_care_taker':
        return selectionGroup.kone_care_taker || []
      case 'value_added_services_addendum':
        return selectionGroup.valueAddedServices || []
      default:
        return selectionGroup.elevator || []
    }
  }

export function getEquipmentAnnualVasListPrice(price: Price): number {
  return price ? round(price.priceValueAddedServices || 0) : 0
}

export function getEquipmentAnnualVasSalesPrice(price: Price): number {
  return round(price.discountedPriceValueAddedServices || 0)
}

const getValueAddedServicesForScope = (selections: OfferSelection[]): SapKey[] =>
  selections
    .filter((s: OfferSelection) => s.module === 'value_added_services' && s.visibility !== 'none')
    .map((s: OfferSelection) => s.key)

export const equipmentGroupHasValueAddedServices = (
  frontlineConfig: ClientFrontlineConfig,
  equipmentGroup: EquipmentGroup,
  quickTenderScope: PricingScope | null
): boolean => {
  let hasVasEntities = false
  const { elevator, escalator, door } = equipmentGroup
  const getSelections = getServices(frontlineConfig)
  if (elevator) {
    hasVasEntities = getValueAddedServicesForScope(getSelections(quickTenderScope, 'elevator')).length > 0
  }
  if (!hasVasEntities && escalator) {
    hasVasEntities = getValueAddedServicesForScope(getSelections(quickTenderScope, 'escalator')).length > 0
  }
  if (!hasVasEntities && door) {
    hasVasEntities = getValueAddedServicesForScope(getSelections(quickTenderScope, 'door')).length > 0
  }
  return hasVasEntities
}

export const anyGroupHasValueAddedServices = (
  frontlineConfig: ClientFrontlineConfig,
  equipmentGroups: Record<string, EquipmentGroup>,
  quickTenderScope: PricingScope | null
): boolean => {
  let hasVasEntities = false
  const getSelections = getServices(frontlineConfig)
  const elevatorVasEntities = getValueAddedServicesForScope(getSelections(quickTenderScope, 'elevator'))
  const escalatorVasEntities = getValueAddedServicesForScope(getSelections(quickTenderScope, 'escalator'))
  const doorVasEntities = getValueAddedServicesForScope(getSelections(quickTenderScope, 'door'))
  const groups = Object.values(equipmentGroups)
  for (const group of groups) {
    const { elevator, escalator, door } = group
    const equipmentVasEntities = elevator
      ? elevatorVasEntities
      : escalator
      ? escalatorVasEntities
      : door
      ? doorVasEntities
      : []
    hasVasEntities = equipmentVasEntities.length > 0
    if (hasVasEntities) {
      break
    }
  }
  return hasVasEntities
}

type ValueAddedServices =
  | CommonValueAddedServicesOptions
  | CommonElevatorEscalatorVASServicesOptions
  | ValueAddedServicesForElevatorOnlyOptions
  | EscalatorOnlyVASServiceOptions

const hasValidChoice = (choice: string) => choice !== '0'

const isValueValid = (val: string | string[] | undefined): boolean =>
  !R.isNil(val) && !R.isEmpty(val) && (Array.isArray(val) ? val.some(hasValidChoice) : hasValidChoice(val as string))

const isServicePriced = (vasDebugLines: ReadonlyArray<DebugLine>) => (vk: SapKey) =>
  vasDebugLines.find((vdl: DebugLine) => vdl.serviceCode === vk && vdl.mv !== 0)

const extractValidVASKeys = (profile: EquipmentProfile, vasDebugLines: ReadonlyArray<DebugLine>) => {
  const checkPrice = isServicePriced(vasDebugLines)
  return (vk: SapKey) => isValueValid(profile[vk as keyof ValueAddedServices]) && checkPrice(vk)
}

const getEnabledVASItems = (prices: Price[], allVASKeysInFL: SapKey[]) => {
  let enabledItems: SapKey[] = []
  for (const p of prices) {
    const {
      priceDetails: { price, profile }
    } = p
    const vasDebugLines = (price as NewDebugFields).debugValueAddedServices
    const vasItemsInEquipments = allVASKeysInFL.filter(extractValidVASKeys(profile, vasDebugLines))
    enabledItems = R.uniq(enabledItems.concat(vasItemsInEquipments))
    if (enabledItems.length === allVASKeysInFL.length) {
      break
    }
  }
  return enabledItems
}

// Used by Offer creation
export function getVasEntitiesByPricebooks(
  prices: Price[],
  quickTenderScope: PricingScope | null
): Record<string, VasPricebook> {
  const allVASKeysInFL = getAllVASKeys()
  const allEnabledVASItems = getEnabledVASItems(prices, allVASKeysInFL)
  return value_added_services.reduce((pricebooks: Record<string, VasPricebook>, vas_item: VASItem) => {
    const { debug_label, pricebook_description, pricebook_name, key } = vas_item
    if (!allEnabledVASItems.includes(key)) {
      return pricebooks
    }
    if (!pricebooks[pricebook_name]) {
      pricebooks[pricebook_name] = {
        description: pricebook_description,
        entities: [],
        sapKey: key
      }
    }
    pricebooks[pricebook_name].entities.push(debug_label)
    pricebooks[pricebook_name].sapKey = key
    const quickTenderScopeItem = quickTenderScope ? vas_item[quickTenderScope] : null
    if (quickTenderScopeItem) {
      pricebooks[pricebook_name].entities = pricebooks[pricebook_name].entities.concat(quickTenderScopeItem)
    }
    return pricebooks
  }, {})
}

// TODO: Remove as part of GSM Clean up
export function checkGsmSelectionInOffering(prices: Price[]) {
  const isGSMSelected = getEnabledVASItems(prices, gsmOfferingSapKey as SapKey[])
  return isGSMSelected ? isGSMSelected.length > 0 : false
}

export function getVASPricesByGendocKey(
  selectedVasKeys: SapKey[],
  prices: Price[],
  prefix: GendocGroupPrefix = {
    yearly: 'TENDER_PRICE',
    monthly: 'MONTHLY_TENDER_PRICE'
  }
): GendocPriceGroups[] {
  if (!Array.isArray(prices)) {
    return []
  }
  const monthlyPriceByGroups = getMonthlySalesPriceByKey(prices, selectedVasKeys)
  return Object.keys(monthlyPriceByGroups).reduce((allGroups: GendocPriceGroups[], key: string) => {
    const monthlyPriceForGroup: number = monthlyPriceByGroups[key]
    return [
      ...allGroups,
      [transformGendocKey(key, prefix.monthly), round(monthlyPriceForGroup, 2)],
      [transformGendocKey(key, prefix.yearly), round(monthlyPriceForGroup * 12, 2)]
    ]
  }, []) as GendocPriceGroups[]
}

export function getMonthlySalesPriceByKey(prices: Price[], selectedVasKeys: SapKey[]): GendocPriceGroupObject {
  return prices.reduce(
    (consolidatedKeys: GendocPriceGroupObject, price: Price) =>
      value_added_services.reduce((allGroups: GendocPriceGroupObject, vItem: VASItem) => {
        const { key } = vItem
        if (selectedVasKeys.includes(key)) {
          const bundlePriceForVasItem = price.bundlePrice && price.bundlePrice.find(bp => bp.key === key)
          const discountedPriceForKey = bundlePriceForVasItem
            ? round(bundleDiscountedPrice(bundlePriceForVasItem) / 12)
            : getMonthlyPriceForVasItems([vItem], price, price.priceDetails.scope)
          const existingPrice = allGroups[key] || 0
          return {
            ...allGroups,
            [key]: discountedPriceForKey + existingPrice
          }
        }
        return allGroups
      }, consolidatedKeys),
    {}
  )
}

// Used by Gendoc
export function getVASPricesByGendocCategory(
  prices: Price[],
  prefix: GendocGroupPrefix = {
    yearly: 'TENDER_PRICE',
    monthly: 'MONTHLY_TENDER_PRICE'
  },
  isEscalationFlowWithVasAddendum?: boolean
): GendocPriceGroups[] {
  if (!Array.isArray(prices)) {
    return []
  }
  const monthlyPriceByGroups = getMonthlySalesPriceByGroups(prices, isEscalationFlowWithVasAddendum)
  return Object.keys(monthlyPriceByGroups).reduce((allGroups: GendocPriceGroups[], group: string) => {
    const monthlyPriceForGroup: number = monthlyPriceByGroups[group]
    return [
      ...allGroups,
      [transformGendocKey(group, prefix.monthly), round(monthlyPriceForGroup, 2)],
      [transformGendocKey(group, prefix.yearly), round(monthlyPriceForGroup * 12, 2)]
    ]
  }, []) as GendocPriceGroups[]
}

function transformGendocKey(group: string, prefix: string = ''): string {
  return prefix.toUpperCase() + '_' + group.toUpperCase()
}

function getMonthlySalesPriceByGroups(
  prices: Price[],
  isEscalationFlowWithVasAddendum?: boolean
): GendocPriceGroupObject {
  return prices.reduce(
    (consolidatedGroups: GendocPriceGroupObject, price: Price) =>
      value_added_services.reduce((allGroups: GendocPriceGroupObject, vItem: VASItem) => {
        const { group, key } = vItem
        if (!group && isEscalationFlowWithVasAddendum) {
          return allGroups
        }
        const bundlePriceForVasItem = price.bundlePrice && price.bundlePrice.find(bp => bp.key === key)
        const discountedPriceForKey = bundlePriceForVasItem
          ? round(bundleDiscountedPrice(bundlePriceForVasItem) / 12)
          : getMonthlyPriceForVasItems([vItem], price, price.priceDetails.scope)
        const existingGroup = allGroups[group]
        if (!existingGroup) {
          return {
            ...allGroups,
            [group]: discountedPriceForKey
          }
        }
        return {
          ...allGroups,
          [group]: existingGroup + discountedPriceForKey
        }
      }, consolidatedGroups),
    {}
  )
}

export function consolidatePricesFromGroups(
  pricesByGroup: GendocPriceGroups[],
  prefix: GendocGroupPrefix
): GendocPriceGroups[] {
  const { yearly, monthly } = prefix
  return pricesByGroup.reduce(
    (totalPriceGroup: GendocPriceGroups[], groupPrice: GendocPriceGroups) => {
      const yearlyTotalPriceGroup: GendocPriceGroups = totalPriceGroup[0]
      const monthlyTotalPriceGroup: GendocPriceGroups = totalPriceGroup[1]
      const [groupPriceLabel, groupPriceVal] = groupPrice
      return [
        [
          yearlyTotalPriceGroup[0],
          R.add(yearlyTotalPriceGroup[1], groupPriceLabel.startsWith(yearly) ? groupPriceVal : 0)
        ],
        [
          monthlyTotalPriceGroup[0],
          R.add(monthlyTotalPriceGroup[1], groupPriceLabel.startsWith(monthly) ? groupPriceVal : 0)
        ]
      ] as GendocPriceGroups[]
    },
    [
      [(yearly.toUpperCase() + '_VAS') as keyof CommonEquipmentVASGroups, 0],
      [(monthly.toUpperCase() + '_VAS') as keyof CommonEquipmentVASGroups, 0]
    ]
  )
}

export function extractPriceGroupsForEquipmentType(
  pricesByGroup: GendocPriceGroups[],
  prefix: GendocGroupPrefix,
  category: EquipmentCategory
): GendocPriceGroups[] {
  const { yearly, monthly } = prefix
  return pricesByGroup.reduce((allGroups: GendocPriceGroups[], groupPrice: GendocPriceGroups) => {
    const vasGroupName = groupPrice[0].startsWith(yearly)
      ? groupPrice[0].split(yearly + '_')[1]
      : groupPrice[0].split(monthly + '_')[1]
    const vasGroup = value_added_services.find(v => v.group === vasGroupName.toLowerCase())
    return vasGroup && vasGroup.equipment_category.includes(category) ? [...allGroups, groupPrice] : allGroups
  }, [])
}

export const getMappedContractPricesByGroup = (
  groupedPrices: GendocPriceGroups[]
): Array<[keyof CommonEquipmentVASGroups, string]> =>
  groupedPrices.map<[keyof CommonEquipmentVASGroups, string]>((group: GendocPriceGroups) => [
    group[0],
    ensureTwoDecimals(group[1])
  ])

export const getMappedContractPricesByKey = (groupedPrices: GendocPriceGroups[]): Array<[string, string]> =>
  groupedPrices.map<[string, string]>((key: GendocPriceGroups) => [key[0], ensureTwoDecimals(key[1])])

export const getEquipmentContractGroupPrices = (groupedPrices: GendocPriceGroups[]): CommonEquipmentVASGroups =>
  groupedPrices.reduce<CommonEquipmentVASGroups>(
    (all: CommonEquipmentVASGroups, group: GendocPriceGroups) => ({
      ...all,
      [group[0]]: ensureTwoDecimals(group[1])
    }),
    {} as CommonEquipmentVASGroups
  )

// Used by SAP OI
export function getVasKeysByServiceLines(selectedKeys: SapKey[]): Array<SapKey[]> {
  const service_levels: Record<string, SapKey[]> = selectedKeys.reduce(
    (consolidated: Record<string, SapKey[]>, vk: SapKey) => {
      const selectedVasItem = value_added_services.find(vas => vas.key === vk)
      if (!selectedVasItem) {
        return consolidated
      }
      const { service_level, key } = selectedVasItem
      consolidated[service_level] = consolidated[service_level] || []
      return { ...consolidated, [service_level]: consolidated[service_level].concat([key]) }
    },
    {}
  )
  return Object.values(service_levels)
}

export function is247ConnectSelected(price: Price) {
  if (!price.priceValueAddedServices) {
    return false
  }
  const vasDebugLines = (price.priceDetails.price as NewDebugFields).debugValueAddedServices
  const price247Connect = vasDebugLines
    ? vasDebugLines.find((line: { label: string; value: string }) => line.label.includes('(kcsm_24_7_connect)'))
    : false
  return price247Connect && price247Connect.mv > 0
}

function getKey(item: VASItem): SapKey {
  return R.prop('key', item)
}

export function getAllVASKeysByCategory() {
  return R.reduce(
    (byCategory, item) => R.assoc(getKey(item), item.equipment_category, byCategory),
    {} as Record<SapKey, EquipmentCategory[]>,
    value_added_services
  )
}

export function getAllVASKeys(): SapKey[] {
  return R.map(getKey, value_added_services)
}

export function getPriceForSelections(price: Price, selections: Selections): number {
  const allVasKeys = getAllVASKeys()
  return Object.keys(selections).reduce((previousPrice: number, selectionKey: SapKey) => {
    if (allVasKeys.includes(selectionKey)) {
      let annualBundleDiscount = 0
      if (Array.isArray(price.bundlePrice) && price.bundlePrice.length > 0) {
        annualBundleDiscount = sumPrices(price.bundlePrice.filter(bp => bp.key === selectionKey).map(bundleDiscount))
      }
      const isVasDiscountEnabled = checkPriceHasVasDiscountDetails([price])
      const vasItemFromConfig = value_added_services.find(v => v.key === selectionKey)
      return (
        previousPrice +
        (vasItemFromConfig
          ? isVasDiscountEnabled
            ? checkPriceForVasDiscountFromDebugKey(price, vasItemFromConfig.debug_label)
            : getVasItemMonthlyPrice(price, vasItemFromConfig.debug_label)
          : 0) -
        annualBundleDiscount / 12.0
      )
    }
    return previousPrice
  }, 0)
}

export const getVasItemMonthlyPrice = (price: Price, debugLineItemKey: string) => {
  if (!price.priceValueAddedServices || !debugLineItemKey) {
    return 0
  }
  const vasDebugLines = (price.priceDetails.price as NewDebugFields).debugValueAddedServices
  const totalPriceDebugLine = vasDebugLines
    ? vasDebugLines.find((line: { label: string; value: string }) => line.label.includes('(' + debugLineItemKey + ')'))
    : 0
  return totalPriceDebugLine ? totalPriceDebugLine.mv : 0
}

export const checkPriceForVasDiscountFromDebugKey = (price: Price, debugKeyOrSapKey: string) => {
  if (!debugKeyOrSapKey) {
    return 0
  }
  const servicekey =
    debugKeyOrSapKey === 'kcsm_apf_info_owner' ? 'KCSM_APF_INFO_300_OWNER' : debugKeyOrSapKey.toUpperCase()
  const vasDiscountedServices = price.vasServicesDiscountDetails?.vasService

  /*  Return 0 for the kcsm care online service in vas oppurtunities,
  since disount is alreay provided for care online and added as part of 24/7 connect service  */
  return price.priceDetails.scope === 'value_added_services_addendum' && debugKeyOrSapKey === 'kcsm_care_online'
    ? 0
    : vasDiscountedServices?.find(vasService => vasService.sapKey === servicekey)?.discountedPriceMonthly || 0
}

export const getGendocVasNodesForRenegoEscaltionFlow = (
  prefix: GendocGroupPrefix = {
    yearly: 'PRICE_YEARLY',
    monthly: 'PRICE_MONTHLY'
  }
): Record<keyof Partial<CommonEquipmentVASGroups>, number> => {
  const vasLineItems = ['VAS', 'CONNECTED_SERVICES']
  /* For  renego escalation flow vas value will always be 0.0 */
  const value = 0.0
  return vasLineItems.reduce(
    (allGroups, group: string) => ({
      ...allGroups,
      [transformGendocKey(group, prefix.yearly)]: value,
      [transformGendocKey(group, prefix.monthly)]: value
    }),
    {}
  ) as Record<keyof Partial<CommonEquipmentVASGroups>, number>
}

/*
  Sends the value added service setUpFee keys configured for the equipment type
  At present this configuration is hardcoded within this function to allow garbage collection
  In future, this configuration could be part of value added service metadata in database
  and fetched during app initialization

  Used by getEquipmentSetupFees function within dataGridCommon file
*/
export const fetchSetupFeeKeysForEquipment = (equipmentType: EquipmentCategory): Array<keyof VasSetupFeeEntity> => {
  const vasSetupFeeEntities: Record<EquipmentType, Array<keyof VasSetupFeeEntity>> = {
    elevator: [
      'KONEElevatorCallSetupPaid',
      'KONEInfo300ServiceSetupPaid',
      'airPurifierServiceSetupPaid',
      'connectedServices247SetupPaid',
      'elevatorCallAPISetupPaid',
      'serviceInfoAPISetupPaid',
      'serviceRobotAPISetupPaid',
      'equipmentStatusAPISetupPaid'
    ],
    escalator: ['connectedServices247SetupPaid', 'serviceInfoAPISetupPaid', 'equipmentStatusAPISetupPaid'],
    door: ['connectedServices247SetupPaid'],
    dumbwaiter: [],
    autowalk: [],
    stairlift: [],
    resiflow: [],
    other: []
  }
  return vasSetupFeeEntities[equipmentType]
}

export const getVasSetupFeeKeys: Record<string, SapKey> = {
  connectedServices247SetupPaid: 'KCSM_24_7_CONNECT',
  KONEInfo300ServiceSetupPaid: 'KCSM_APF_INFO_300',
  equipmentStatusAPISetupPaid: 'KCSM_APF_EQUIP_STAT_API',
  serviceInfoAPISetupPaid: 'KCSM_APF_SERV_INFO_API',
  elevatorCallAPISetupPaid: 'KCSM_APF_ELEV_CALL_API',
  serviceRobotAPISetupPaid: 'KCSM_APF_SERV_ROBOT_API',
  airPurifierServiceSetupPaid: 'KCSM_AIR_PURIFIER',
  KONEElevatorCallSetupPaid: 'KCSM_MOBILE_ELEV_CALL'
}

const setupFeePrefix = 'setupFee_'
const gsmSetupFeePrefix = 'gsmSetupFee_'
const maxPossibleVASDiscount = 100
const minPossibleVASDiscount = -100
export function getFrontlineConfiguredVasSetupFees(
  pricingVariables: PRecord<PricingCategory, any>,
  category: PricingCategory,
  vasPrice: VasSetupfeeSavedData | undefined,
  isGSMOffer: boolean | null
) {
  const prefix = isGSMOffer ? gsmSetupFeePrefix : setupFeePrefix
  const vasSetUpFeeServiceDetails: VASSetUpFeeServiceDetails = {}
  const getConfig = pricingVariables[category]
  const vasSetupFee: VASSetUpFeeServiceDetails = R.filter(
    variable => variable.startsWith(prefix),
    Object.keys(getConfig)
  )
    .filter(key => {
      const { targetedPrice } = R.fromPairs(getConfig[key])
      return (targetedPrice as number) > 0
    })
    .reduce((vasPriceObj, key) => {
      const setupFeeKey = key.slice(prefix.length)
      const { targetedPrice } = R.fromPairs(getConfig[key])
      vasPriceObj[setupFeeKey as SapKey] = createVasDiscountObject(
        vasPrice,
        category,
        setupFeeKey,
        targetedPrice as number
      )
      return vasPriceObj
    }, vasSetUpFeeServiceDetails)
  return vasSetupFee
}

export function calculateDiscountValue(price: number, percentage: number): number {
  const discountedPrice = price * (1.0 - percentage / 100.0)
  return parseFloat(discountedPrice.toFixed(2))
}

export function calculateDiscountPercentage(value: number, unchangedPrice: number): number {
  return (100 * (unchangedPrice - value)) / unchangedPrice
}

function createVasDiscountObject(
  vasPrice: VasSetupfeeSavedData | undefined,
  category: string,
  service: string,
  targetedPrice: number
): ServiceLevelVASDiscountPrice {
  const findCategory = isValidSetupFee(vasPrice)
    ? vasPrice!.serviceLevelVASSetupDiscount[category as EquipmentCategory]
    : {}
  const vasServices = findCategory && findCategory[service as SapKey]
  const discount = vasServices ? vasServices?.discount : 0
  const discountedPrice = vasServices ? vasServices.setupFeeAfterDiscount : targetedPrice
  const price: ServiceLevelVASDiscountPrice = {
    setupFeeAfterDiscount: checkIsSetupFeeUpdatedInMBS(vasServices, targetedPrice)
      ? calculateDiscountValue(targetedPrice, discount)
      : discountedPrice,
    setupFee: targetedPrice,
    discount
  }
  return price
}

function checkIsSetupFeeUpdatedInMBS(vasServices: ServiceLevelVASDiscountPrice | undefined, targetedPrice: number) {
  const existingSetUpFee = vasServices ? vasServices?.setupFee : targetedPrice
  return existingSetUpFee !== targetedPrice
}

export function onSetupFeePaidChange(equipmentId: string, key: SapKey, prices: Price[]) {
  const equipmentPrice = prices.find(price => price.id === equipmentId)
  const paidStatus = equipmentPrice?.priceDetails.profile.vasSetupFeePaidServices
  const isKeyAvailable = R.pluck('key', paidStatus as VasSetupfeePaidStatus[]).includes(key)
  equipmentPrice &&
    (equipmentPrice.priceDetails.profile.vasSetupFeePaidServices = isKeyAvailable
      ? paidStatus?.map(status => {
          if (status.key === key) {
            status.isSetupfeePaid = !status.isSetupfeePaid
          }
          return status
        })
      : paidStatus!.push({ key, isSetupFeePaidInCRM: false, isSetupfeePaid: true }) > 0
      ? paidStatus
      : [])
  return prices
}

export function getIncludedVasServicesForOffer(
  equipmentGroups: EquipmentGroups,
  groups: GroupPriceBreakdown[],
  prices: Price[],
  quickTenderScope: PricingScope | null
): SelectedVasServices[] {
  const categoryServices: SelectedVasServices[] = []
  Object.values(equipmentGroups).forEach(equipmentGroup => {
    const priceGroup = groups.find(group => group.salesToolId === equipmentGroup.salesToolId)!
    const groupedVasItems = getGroupedVasItems(prices, quickTenderScope, equipmentGroup)
    groupedVasItems.forEach(category => {
      category.equipments.forEach(equipments => {
        const { equipmentId, sapKeys } = equipments
        const priceSection = priceGroup ? priceGroup.valueAddedServices : null
        const equipmentDetails = priceSection
          ? priceSection.equipmentPrices.find(equipmentPrice => equipmentPrice.salesToolId === equipmentId)
          : null
        if (equipmentDetails) {
          const { equipment } = equipmentDetails
          categoryServices.push({ key: equipment.equipmentCategory, sapKeys })
        }
      })
    })
  })
  categoryServices.sort((services1, services2) => (services1.key > services2.key ? 1 : -1))
  return categoryServices
}

export function getTotalSetupFee(
  equipmentGroups: EquipmentGroups,
  groups: GroupPriceBreakdown[],
  prices: Price[],
  quickTenderScope: PricingScope | null,
  vasSetUpFee: Partial<Record<EquipmentCategory, VASSetUpFeeServiceDetails>>
) {
  const totalPrices = Object.values(equipmentGroups).map(equipmentGroup => {
    const pricePerGroup = prices.filter(p => p.groupId === equipmentGroup.salesToolId)
    const priceGroup = groups.find(group => group.salesToolId === equipmentGroup.salesToolId)!
    const groupedVasItems = getGroupedVasItemsForEquipmentGroup(pricePerGroup, quickTenderScope, false, true)
    const getEquipments = R.flatten(groupedVasItems.map(vasItems => vasItems.equipments)).filter(
      eq => !eq.vasServicesWithSetupFeePaid
    )
    const totalPrice = !R.isNil(priceGroup)
      ? getTotalSetupFeeByCategory(getEquipments, vasSetUpFee, priceGroup.valueAddedServices)
      : 0
    return totalPrice && R.sum(totalPrice)
  })
  return R.sum(totalPrices)
}

export function getTotalSetupFeeByCategory(
  vasServiceGroup: EquipmentVASItem[],
  vasSetUpFee: Partial<Record<EquipmentCategory, VASSetUpFeeServiceDetails>>,
  priceSection: GroupSectionPriceBreakdown
): number[] {
  const vasCategories =
    !R.isEmpty(vasServiceGroup) && !R.isEmpty(priceSection) && !R.isEmpty(priceSection.equipmentPrices)
      ? vasServiceGroup.map(service => {
          const groupEquipmentBreakDown = priceSection.equipmentPrices.find(
            ep => ep.salesToolId === service.equipmentId
          )!
          const equipment = groupEquipmentBreakDown?.equipment
          return equipment ? { equipmentCatgeory: equipment.equipmentCategory, key: service.sapKeys } : undefined
        })
      : []
  return vasCategories
    .filter(vasCat => vasCat !== undefined)
    .map(vasCategory => {
      const categoryWiseData = vasSetUpFee[vasCategory!.equipmentCatgeory]
      const price = categoryWiseData
        ? vasCategory
          ? categoryWiseData[vasCategory.key as SapKey]?.setupFeeAfterDiscount
          : 0
        : 0
      return R.isNil(price) ? 0 : price!
    })
}

export function isValidSetupFee(vasSetupFee: VasSetupfeeSavedData | undefined) {
  return vasSetupFee && R.has('serviceLevelVASSetupDiscount', vasSetupFee)
}

function getIncludedVasServices(validVasServices: SelectedVasServices[], key: string) {
  const selectedVasKeys = validVasServices
    .filter(vasService => vasService.key === key)
    .map(selected => getNormalizedVasServiceList(selected.sapKeys))
  const faltSelectedVasKeys = R.flatten(selectedVasKeys)
  return R.uniq(faltSelectedVasKeys)
}

function getNormalizedVasServiceList(value: SapKey | SapKey[]): SapKey[] {
  const sapKeys: SapKey[] = []
  if (Array.isArray(value)) {
    return value
  } else {
    sapKeys.push(value)
    return sapKeys
  }
}

function getGroupedVasItems(prices: Price[], quickTenderScope: PricingScope | null, equipmentGroup: EquipmentGroup) {
  const price = prices.filter(priceGroup => priceGroup.groupId === equipmentGroup.salesToolId)
  return R.sortBy(
    (q: GroupedVASEntity) => R.toLower(q.name),
    getGroupedVasItemsForEquipmentGroup(price, quickTenderScope)
  )
}

export function getVasSetuFeeModalData(
  services: SelectedVasServices[],
  pricingVariables: PRecord<PricingCategory, any>,
  vasService: VasSetupfeeSavedData | undefined,
  isGSMOffer: boolean | null
): Partial<Record<EquipmentCategory, VASSetUpFeeServiceDetails>> {
  const keyProp = 'key'
  const groupTabs = R.groupBy(R.prop(keyProp), services)
  const getData: Partial<Record<EquipmentCategory, VASSetUpFeeServiceDetails>>[] = []
  Object.keys(groupTabs).forEach(key => {
    const vasSetupFee = getFrontlineConfiguredVasSetupFees(
      pricingVariables,
      key as PricingCategory,
      vasService,
      isGSMOffer
    )
    const selectedData = getIncludedVasServices(services, key)
    const filteredService = filterConfiguredVasServiceWithSelectedServices(selectedData, vasSetupFee)
    if (!R.isEmpty(Object.values(filteredService))) {
      getData.push({ [key as EquipmentCategory]: filteredService })
    }
  })

  return Object.assign({}, ...getData)
}

function filterConfiguredVasServiceWithSelectedServices(
  selectedServices: string[],
  vasSetUpFee: VASSetUpFeeServiceDetails
) {
  const vasSetUpFeeServiceDetails: VASSetUpFeeServiceDetails = Object.keys(vasSetUpFee)
    .filter(key => selectedServices.includes(key))
    .reduce((vasPriceObj, key) => {
      vasPriceObj[key as SapKey] = vasSetUpFee[key as SapKey]
      return vasPriceObj
    }, {} as VASSetUpFeeServiceDetails)
  return vasSetUpFeeServiceDetails
}

export function validateDiscountRange(value: string): boolean {
  const floatValue = parseFloat(value)
  return floatValue < minPossibleVASDiscount || floatValue > maxPossibleVASDiscount
}

export function checkIsVasSetupFeeConfigured(
  setupfeeData: Partial<Record<EquipmentCategory, VASSetUpFeeServiceDetails>>
): boolean {
  const vasServiceObj = Object.values(setupfeeData).map(services => (services ? Object.values(services) : []))
  const flatVasServiceObj = R.flatten(vasServiceObj).filter(service => (service ? service.setupFee > 0 : false))
  return flatVasServiceObj.length > 0
}

export function getConfiguredValueForSelectedService(
  pricingVariables: PRecord<PricingCategory, any>,
  category: string,
  sapKey: SapKey,
  quickTenderScope: PricingScope | null
) {
  const prefix = quickTenderScope === 'gsm_campaign' ? gsmSetupFeePrefix : setupFeePrefix
  const getConfig = pricingVariables[category as EquipmentCategory]
  const configuredPriceDetails = R.filter(variable => variable.startsWith(prefix), Object.keys(getConfig)).filter(
    key => sapKey === key.slice(prefix.length)
  )
  if (!R.isEmpty(configuredPriceDetails)) {
    const { targetedPrice, maxDiscountAllowed, maximumDiscountAllowed } = R.fromPairs(
      getConfig[configuredPriceDetails[0]]
    )
    const discount = R.isNil(maxDiscountAllowed) ? maximumDiscountAllowed : maxDiscountAllowed
    return { targetedPrice, discount }
  } else {
    return {}
  }
}

export function vasSetupFeeGroupData(prices: Price[]): VasServiceGroup[] {
  return R.uniqBy(R.prop('groupId'), prices)
    .map(price => price.groupId)
    .map((groupId: string) => {
      const filteredPrice = prices.filter(price => price.groupId === groupId)
      const equipments: Record<EquipmentCategory, VasEquipmentInGroup[]> = R.groupBy(
        R.prop('equipmentCategory'),
        vasSetupFeeGroupEquipmentDetails(filteredPrice)
      )
      const groupData: VasServiceGroup = {
        groupId,
        groupName: '',
        equipments
      }
      return groupData
    })
}

function vasSetupFeeGroupEquipmentDetails(prices: Price[]): Array<VasEquipmentInGroup> {
  return prices
    .map(price => price.priceDetails.profile)
    .map((profile: EquipmentProfile) => ({
      equipmentId: profile.equipmentId,
      equipmentCategory: profile.equipmentCategory,
      vasSetupFeeServices: profile.vasSetupFeePaidServices
        ?.filter((vas: VasSetupfeePaidStatus) => !vas.isSetupfeePaid)
        .map((vas: VasSetupfeePaidStatus) => vas.key) as SapKey[]
    }))
}

export function getEquipmentEnabledVASSapKey(
  equipmentId: string,
  equipmentCategory: EquipmentCategory,
  groupId: string,
  vasSavedData: VasServiceGroup[] | undefined
) {
  if (!vasSavedData) {
    return []
  } else {
    const groupFound = vasSavedData.find(group => group.groupId === groupId)
    if (groupFound && groupFound.equipments) {
      const equipmentDetails = groupFound.equipments[equipmentCategory]
      const equipmentObj = equipmentDetails?.find(equip => equip?.equipmentId === equipmentId)
      return equipmentObj?.vasSetupFeeServices
    }
    return []
  }
}

export function getNumberOfEqipement(
  vasServiceGroups: VasServiceGroup[],
  equpmentType: EquipmentCategory,
  sapKey: SapKey
) {
  return vasServiceGroups.reduce(
    (count: number, vasServiceGroup: VasServiceGroup) =>
      count +
      (vasServiceGroup.equipments[equpmentType]
        ? vasServiceGroup.equipments[equpmentType]!.reduce(
            (eqCount: number, vasServices: VasEquipmentInGroup) =>
              vasServices.vasSetupFeeServices.includes(sapKey) ? eqCount + 1 : eqCount,
            0
          )
        : 0),
    0
  )
}

export function getVASSetupFeeGroup(keys: Gendoc.VasSetupFeeNode[]) {
  if (keys) {
    return keys.reduce(
      (k, v) => {
        const newKey: ServiceKey = Array.isArray(v) ? R.mergeAll(v) : v
        const category = getGroup(newKey.DATANAME)
        if (category) {
          k[category].push({ ...newKey })
        }
        return k
      },
      { connected_services: [], connected_passenger_exp: [], flow_solutions: [] } as IVas
    )
  } else return { connected_services: [], connected_passenger_exp: [], flow_solutions: [] } as IVas
}

export function isEquipmentAvailableInGroup(
  equipmentId: string,
  equipmentCategory: EquipmentCategory,
  groupId: string,
  vasSavedData: VasServiceGroup[] | undefined
) {
  if (!vasSavedData) {
    return false
  } else {
    const groupFound = vasSavedData.find(group => group.groupId === groupId)
    if (groupFound && groupFound.equipments) {
      const equipmentDetails = groupFound.equipments[equipmentCategory]
      return equipmentDetails?.some(equip => equip?.equipmentId === equipmentId)
    }
    return false
  }
}

export function getVasSetupFeeSapNodeData(
  groupId: string,
  equipmentId: string,
  equipmentCategory: EquipmentCategory,
  vasSetupFeeData: VasSetupfeeSavedData,
  selections: Selections,
  posnr?: string
): VasSetupFeeSAPNode {
  const vasEnabledOnEquipmentList = getEquipmentEnabledVASSapKey(
    equipmentId,
    equipmentCategory,
    groupId,
    vasSetupFeeData.groups
  )
  const keyList = Object.keys(selections)
  const filteredKey = vasEnabledOnEquipmentList?.find(key => keyList.includes(key))
  const keyList1 = equipmentCategory ? vasSetupFeeData!.serviceLevelVASSetupDiscount![equipmentCategory] : {}
  const price = keyList1 && filteredKey ? keyList1[filteredKey]?.setupFeeAfterDiscount : ''
  const sapCode = filteredKey && vasSetupFeeSapValueMapping ? vasSetupFeeSapValueMapping[filteredKey] : ''
  const posnum = posnr || ''
  return {
    posnr: posnum,
    key: filteredKey,
    equipmentId,
    KCSM_VAS_SETUP_FEE: sapCode || '',
    KCSM_VAS_SETUP_FEE_AMT: sapCode !== '' ? price?.toString() : ''
  }
}

export function getVasSetupFeeSAPZConText(vasSetupFeeData: VasSetupfeeSavedData): VasSetupFeeZconInfo[] {
  if (!vasSetupFeeData) {
    return []
  }
  const equipmentCategory = Object.keys(vasSetupFeeData?.serviceLevelVASSetupDiscount)
  const sapZconNodes = equipmentCategory.map((category: EquipmentCategory) => {
    const servicesWithPrice =
      vasSetupFeeData && vasSetupFeeData.serviceLevelVASSetupDiscount
        ? vasSetupFeeData!.serviceLevelVASSetupDiscount![category]
        : {}
    const serviceList = servicesWithPrice ? Object.keys(servicesWithPrice) : []
    return serviceList.map((serviceSapKey: SapKey) => {
      const setupFee = servicesWithPrice![serviceSapKey]?.setupFeeAfterDiscount || 0
      const vasZconInfo: VasSetupFeeZconInfo = {
        equipmentCategory: category,
        vasService: serviceSapKey,
        unitCount: getVASServiceSetupCount(category, vasSetupFeeData.groups, serviceSapKey),
        setupFee
      }
      return vasZconInfo
    })
  })
  return R.flatten(sapZconNodes)
}

function getVASServiceSetupCount(equipmentCategory: EquipmentCategory, groups: VasServiceGroup[], vasKey: SapKey) {
  const validServiceListAcrossGroups = groups
    .map(group => {
      const equipmentList = group.equipments[equipmentCategory] as VasEquipmentInGroup[]
      return equipmentList?.filter(equipment => equipment.vasSetupFeeServices.includes(vasKey))
    })
    .filter(group => group !== undefined)
  return R.flatten(validServiceListAcrossGroups).length
}
