import { Price, PricingScope, SavedOffer, VasServiceDiscount } from '../types/types'
import {
  sumCorePrice,
  sumTotalPrice,
  sumCoreListPrice,
  round,
  sumListPriceWithValueAddedServices,
  sumExtraServicesPrice,
  sumValueAddedServicesListPrice,
  floor
} from './prices'
export const vascareServices = ['KCSM_24_7_CONNECT', 'KCSM_REMOTE_INTERVENTIONS']

// eslint-disable-next-line  no-shadow
export enum DiscountLevel {
  VAS_LEVEL,
  PREDICTIVE_CARE_LEVEL,
  CORE_SERVICES_LEVEL,
  EQUIPMENT_LEVEL,
  SUB_GROUP_LEVEL
}

export function getAverageDiscountPercentage(prices: Price[]): number {
  const discountedPrice = sumTotalPrice(prices)
  const fullPrice = sumListPriceWithValueAddedServices(prices)
  return fullPrice === 0 ? 0 : (100 * (fullPrice - discountedPrice)) / fullPrice
}

export function getRoundedAverageDiscountPercentageCoreAndExtraServices(offer: SavedOffer): number {
  const { isEscalationFlow, prices } = offer
  if (isEscalationFlow.active && isEscalationFlow.escalatedPercentage > 0) {
    return isEscalationFlow.escalatedPercentage * -1
  }
  const discountedPrice = sumCorePrice(prices) + sumExtraServicesPrice(prices)
  const fullPrice = sumCoreListPrice(prices) + sumExtraServicesPrice(prices)
  return round(fullPrice === 0 ? 0 : (100 * (fullPrice - discountedPrice)) / fullPrice)
}

export function getEscalationRoundedAverageDiscountWithVas(offer: SavedOffer): number {
  const {
    isEscalationFlow: {
      escalatedAnnualPrice,
      escalationVasDiscountedPrices,
      initialContractAnnualPrice,
      escalationVasPrices
    }
  } = offer
  const salesPrice = escalatedAnnualPrice + escalationVasDiscountedPrices
  const targetedPrice = initialContractAnnualPrice + escalationVasPrices
  return round((salesPrice / targetedPrice - 1) * -1 * 100)
}

export function getDiscountPercentageCorePerEquipment(p: Price): number {
  const { price, discountedPrice } = p
  return price && price !== 0 && discountedPrice ? (100 * (price - discountedPrice)) / price : 0
}

const applyDiscountPercentageCore = (prices: ReadonlyArray<Price>, percentage: number): Price[] =>
  prices.map(p => {
    const newPrice = p.price != null ? p.price - (p.price * percentage) / 100.0 : null
    return {
      ...p,
      discountedPrice: newPrice,
      discount: percentage
    }
  })

const applyDiscountToGivenPrice = (price: number, percentage: number): number => price - (price * percentage) / 100.0

export const applyVasDiscountTotalAndGroupPercentage = (prices: ReadonlyArray<Price>, percentage: number): Price[] => {
  if (percentage === 0) {
    return setZeroPercentageDiscount(prices)
  }
  return roundOfPriceWithValue(
    prices.map(price => {
      const vasDiscountDetails = price.vasServicesDiscountDetails || {}
      const vasServicesDiscount =
        vasDiscountDetails.vasService?.map(vasItem => {
          const newDiscoutedPrice = applyDiscountToGivenPrice(vasItem.unDiscountedPriceMonthly, percentage)
          return {
            ...vasItem,
            discountedPrice: newDiscoutedPrice * 12,
            discountedPriceMonthly: newDiscoutedPrice
          }
        }) || []

      const discountedPrice = reCalculateVasDiscountPrices(true, price, percentage)
      const predictiveCarePrice = reCalculatePredictivePrice(price, percentage)
      const vasServicesDiscountDetails = vasServicesDiscount && {
        ...vasDiscountDetails,
        vasService: vasServicesDiscount,
        predictiveCarePrice
      }
      return {
        ...price,
        discountedPrice,
        vasServicesDiscountDetails,
        discountedPriceValueAddedServices: vasServicesDiscount?.reduce((acc, vas) => acc + vas.discountedPrice, 0) || 0
      }
    }),
    0,
    true
  )
}

const reCalculateVasDiscountPrices = (
  isCore: boolean,
  price: Price,
  percentage: number,
  predictiveTotal: number = 0
) => {
  const sumList = isCore
    ? price.price || 0
    : predictiveTotal > 0
    ? predictiveTotal
    : (price.price || 0) + (price.priceValueAddedServices || 0)
  const listPriceWithExtraService = sumExtraServicesPrice([price]) + sumList
  return sumList - listPriceWithExtraService + listPriceWithExtraService * (1.0 - percentage / 100.0)
}

const reCalculatePredictivePrice = (price: Price, percentage: number) => {
  const vasDiscountDetails = price.vasServicesDiscountDetails
  const updatedVasTotalPrice = vasDiscountDetails?.vasService
    ?.filter(service => vascareServices.includes(service.sapKey))
    .reduce((acc, val) => acc + val.unDiscountedPrice, 0)
  return reCalculateVasDiscountPrices(false, price, percentage, (updatedVasTotalPrice || 0) + (price.price || 0))
}

const setZeroPercentageDiscount = (prices: ReadonlyArray<Price>): Price[] =>
  prices.map(price => {
    const vasDiscountDetails = price.vasServicesDiscountDetails
    const vasService = vasDiscountDetails?.vasService
    const vasServicesDiscountDetails = vasService?.map(vasItem => ({
      ...vasItem,
      discountedPrice: vasItem.unDiscountedPrice,
      discountedPriceMonthly: vasItem.unDiscountedPriceMonthly
    }))
    return {
      ...price,
      discountedPrice: price.price || 0,
      discountedPriceValueAddedServices: price.priceValueAddedServices || 0,
      vasServicesDiscountDetails: {
        ...vasDiscountDetails,
        vasService: vasServicesDiscountDetails,
        predictiveCarePrice:
          (price.price || 0) +
          (vasServicesDiscountDetails
            ?.filter(service => vascareServices.includes(service.sapKey))
            .reduce((acc, vas) => acc + vas.discountedPrice, 0) || 0)
      }
    }
  })

const calculateDiscountValue = (price: Price, percentage: number) => {
  const listPrice = sumExtraServicesPrice([price]) + sumCoreListPrice([price])
  return sumCoreListPrice([price]) - listPrice + listPrice * (1.0 - percentage / 100.0)
}

export const applyDiscountPercentage = (prices: ReadonlyArray<Price>, percentage: number): Price[] =>
  prices.map(p => {
    const newPrice = calculateDiscountValue(p, percentage)
    const coreListPrice = sumCoreListPrice([p])
    return {
      ...p,
      discountedPrice: newPrice,
      discount: coreListPrice === 0 ? 0 : 100.0 - ((100.0 * newPrice) / (100.0 * coreListPrice)) * 100.0,
      vasServicesDiscountDetails: {}
    }
  })

export const applyDiscountValue = (prices: ReadonlyArray<Price>, value: number): Price[] => {
  const total = sumExtraServicesPrice(prices) + sumCoreListPrice(prices)
  const discount = total - value
  const totalCore = sumCoreListPrice(prices)
  const percentage = totalCore === 0 ? 0 : (100 * discount) / totalCore
  return applyDiscountPercentageCore(prices, percentage)
}

export const applyDiscountValueCore = (prices: ReadonlyArray<Price>, value: number): Price[] => {
  const total = sumCoreListPrice(prices)
  const discount = total - value
  const percentage = total === 0 ? 0 : (100 * discount) / total
  return applyDiscountPercentageCore(prices, percentage)
}

export const applyVasDiscountSubGroupValue = (
  prices: ReadonlyArray<Price>,
  value: number,
  isTotal: boolean = true
): Price[] => {
  const total =
    sumCoreListPrice(prices) + sumValueAddedServicesListPrice(prices) + (isTotal ? sumExtraServicesPrice(prices) : 0)
  const discount = total - value
  const totalVasCore = sumCoreListPrice(prices) + sumValueAddedServicesListPrice(prices)
  const percentage = totalVasCore === 0 ? 0 : (100 * discount) / totalVasCore
  const valueWithOutExtraPrice = isTotal ? value - sumExtraServicesPrice(prices) : value
  return roundOfPriceWithValue(applyVasDiscountOnPriceChange(prices, percentage), valueWithOutExtraPrice, false)
}

export const roundOfPriceWithValue = (prices: Price[], value: number, isPercentage: boolean): Price[] => {
  const totalDiscountedPrice = prices.reduce(
    (acc, price) => acc + (price.discountedPrice || 0) + (price.discountedPriceValueAddedServices || 0),
    0
  )
  const difference = value - totalDiscountedPrice
  const adjustmentPerPrice = difference / prices.length
  let remainder = 0
  let vasRemainder = 0
  return prices.map(price => {
    const newDiscountedPrice = (price.discountedPrice || 0) + (!isPercentage ? adjustmentPerPrice : 0)
    const orginalVasPrice = price.discountedPriceValueAddedServices || 0
    let roundedPrice = floor(newDiscountedPrice)
    let roundedVasPrice = floor(orginalVasPrice)
    remainder += newDiscountedPrice - roundedPrice
    vasRemainder += orginalVasPrice - roundedVasPrice
    if (remainder >= 0.005) {
      roundedPrice += 0.01
      remainder -= 0.01
    }

    if (vasRemainder >= 0.005) {
      roundedVasPrice += 0.01
      vasRemainder -= 0.01
    }
    const roundedVasServices = roundOfVasPrice(price.vasServicesDiscountDetails?.vasService || [], roundedVasPrice)
    const updatedPredictiveVasTotal = roundedVasServices
      ?.filter(service => vascareServices.includes(service.sapKey))
      .reduce((acc, val) => acc + val.discountedPrice, 0)
    return {
      ...price,
      discountedPrice: roundedPrice,
      discountedPriceValueAddedServices: roundedVasPrice,
      vasServicesDiscountDetails: {
        ...price.vasServicesDiscountDetails,
        vasService: roundedVasServices,
        predictiveCarePrice: (roundedPrice || 0) + (updatedPredictiveVasTotal || 0)
      }
    }
  })
}

const roundOfVasPrice = (vasServices: VasServiceDiscount[], discountedVasPrice: number): VasServiceDiscount[] => {
  const totalVasServicePriceAfter = vasServices.reduce((acc, service) => acc + (service.discountedPrice || 0), 0)
  const difference = discountedVasPrice - totalVasServicePriceAfter
  const adjustmentVasPrice = difference / vasServices.length
  let vasRemainder = 0
  return vasServices.map(vasPrice => {
    const newDiscountedPrice = (vasPrice.discountedPrice || 0) + adjustmentVasPrice
    let roundedPrice = floor(newDiscountedPrice)
    vasRemainder += newDiscountedPrice - roundedPrice
    if (vasRemainder >= 0.005) {
      roundedPrice += 0.01
      vasRemainder -= 0.01
    }
    return {
      ...vasPrice,
      discountedPrice: roundedPrice,
      discountedPriceMonthly: roundedPrice / 12
    }
  })
}

export const applyVasDiscountToPredictivePrice = (prices: Price[], percentage: number, value: number): Price[] =>
  prices.map(price => {
    const newPrice = price.price != null ? price.price - (price.price * percentage) / 100.0 : null
    const vasDiscountDetails = price.vasServicesDiscountDetails || {}
    const discountedVasServices: VasServiceDiscount[] =
      vasDiscountDetails.vasService?.map(vasItem => {
        if (vascareServices.includes(vasItem.sapKey)) {
          const vasMonthlyPrice = vasItem.unDiscountedPriceMonthly
          const newDiscoutedPrice = vasMonthlyPrice - (vasMonthlyPrice * percentage) / 100.0
          return {
            ...vasItem,
            discountedPrice: (newDiscoutedPrice || 0) * 12,
            discountedPriceMonthly: newDiscoutedPrice || 0
          }
        }
        return vasItem
      }) || []
    return {
      ...price,
      discountedPrice: newPrice,
      discountedPriceValueAddedServices: discountedVasServices.reduce(
        (acc, vas) => acc + (vas.discountedPrice || 0),
        0
      ),
      discount: percentage,
      vasServicesDiscountDetails: {
        ...price.vasServicesDiscountDetails,
        vasService: discountedVasServices,
        predictiveCarePrice: value
      }
    }
  })

const applyVasDiscountOnPriceChange = (prices: ReadonlyArray<Price>, percentage: number): Price[] =>
  prices.map(price => {
    const newPrice = price.price != null ? price.price - (price.price * percentage) / 100.0 : null
    const newVasPrice =
      price.priceValueAddedServices != null
        ? price.priceValueAddedServices - (price.priceValueAddedServices * percentage) / 100.0
        : null
    const vasDiscountDetails = price.vasServicesDiscountDetails || {}
    const discountedVasServices: VasServiceDiscount[] =
      vasDiscountDetails.vasService?.map(vasItem => {
        const vasMonthlyPrice = vasItem.unDiscountedPriceMonthly
        const newDiscoutedPrice =
          vasMonthlyPrice != null ? vasMonthlyPrice - (vasMonthlyPrice * percentage) / 100.0 : null
        return {
          ...vasItem,
          discountedPrice: (newDiscoutedPrice || 0) * 12,
          discountedPriceMonthly: newDiscoutedPrice || 0
        }
      }) || []
    const updatedPredictiveVasTotal = discountedVasServices
      ?.filter(service => vascareServices.includes(service.sapKey))
      .reduce((acc, val) => acc + val.discountedPrice, 0)
    return {
      ...price,
      discountedPrice: newPrice,
      discountedPriceValueAddedServices: newVasPrice,
      discount: percentage,
      vasServicesDiscountDetails: {
        ...price.vasServicesDiscountDetails,
        vasService: discountedVasServices,
        predictiveCarePrice: (newPrice || 0) + (updatedPredictiveVasTotal || 0)
      }
    }
  })

export const applyDiscountsValueCore = (prices: ReadonlyArray<Price>, newPrice: number): Price[] =>
  prices.map(p => {
    const percentage = p.price && p.price !== 0 ? ((p.price - newPrice) * 100) / p.price : 0
    return {
      ...p,
      discountedPrice: newPrice,
      discount: percentage
    }
  })

export const isVasDiscountEnabled = (
  quickTenderScope: PricingScope | null,
  isVasDiscountEnabledFlConfig: boolean | undefined
) => {
  const vasDiscountNotApplicaleScopes: PricingScope[] = ['konexion', 'gsm_campaign']
  const checkScope = (quickTenderScope && !vasDiscountNotApplicaleScopes.includes(quickTenderScope)) || false
  return checkScope && (isVasDiscountEnabledFlConfig || false)
}

export const isVasOppWithVasDiscount = (
  quickTenderScope: PricingScope | null,
  isVasDiscountEnabledFlConfig: boolean | undefined
) =>
  quickTenderScope === 'value_added_services_addendum' &&
  isVasDiscountEnabled(quickTenderScope, isVasDiscountEnabledFlConfig)
